<template>
  <div v-if="fields">
    <el-row :gutter="15">
      <el-col :md="20">{{ fields.street.label }}
        <el-input v-model="form.street.value"></el-input>
      </el-col>
      <el-col :md="4">{{ fields.number.label }}
        <el-input v-model="form.number.value"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :md="16">{{ fields.city.label }}
        <el-input v-model="form.city.value"></el-input>
      </el-col>
      <el-col :md="8">{{ fields.state.label }}
        <el-input v-model="form.state.value"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="15">
      <el-col :md="8">{{ fields.zip.label }}
        <el-input v-model="form.zip.value"></el-input>
      </el-col>
      <el-col :md="16">{{ fields.country.label }}
        <el-input v-model="form.country.value"></el-input>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import {ref, watch} from "vue"

export default {
  name: "FormAddress",
  props: {
    fields: Object
  },
  emits: ["update"],
  setup(props, context) {
    let form = {};
    for (let f in props.fields) {
      form[f] = ref(props.fields[f].value);
      watch(form[f], () => {
        context.emit("update", f, form[f].value);
      })
    }
    return {form};
  },
  methods: {
    clear (){
      for (let f in this.form){
        this.form[f].value = this.fields[f].value;
      }
    }
  }


}
</script>

<style scoped>

</style>
